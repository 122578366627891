import { Link } from "gatsby"
import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Heading, Text, Styled } from "theme-ui"

import mediadatenAutorenwelt from "../content/misc/mediadaten_autorenwelt-newsletter.pdf"
import mediadatenUschtrin from "../content/misc/mediadaten_uschtrin.pdf"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Mediadaten"
      description="Unsere Mediadaten - Falls sie in einem unserer Medien inserieren möchten."
    />
    <Heading as="h1" variant="siteTitle">
      Mediadaten
    </Heading>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      Mediadaten Uschtrin Verlag
    </Heading>
    <Text variant="default">
      Ihre Zielgruppe sind Autorinnen und Autoren? Ganz gleich, wie Sie Ihre
      Werbebotschaft verbreiten möchten, mit der{" "}
      <span sx={{ fontWeight: "bold" }}>»Federwelt«</span> und dem{" "}
      <span sx={{ fontWeight: "bold" }}>»selfpublisher«</span> haben wir die
      richtigen Medien für Sie.
    </Text>
    <Text variant="default">
      Die aktuellen Mediadaten stehen hier zum Download für Sie bereit:
      <br />
      <Styled.a href={mediadatenUschtrin}>
        mediadaten_uschtrin.pdf
      </Styled.a>
    </Text>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      »Federwelt«
    </Heading>
    <Text variant="default">
      Ziel der <span sx={{ fontWeight: "bold" }}>»Federwelt«</span> ist es,
      Schreibende zu professionalisieren. Daher enthält jedes Heft
      praxisorientierte Artikel, die zum Beispiel zeigen, wie man Romane plant,
      Gefühle packend schildert, die richtige Erzählstimme wählt oder das
      Geschriebene und sich selbst am besten vermarktet.
    </Text>
    <Text variant="default">
      Außerdem: Erfahrungsberichte von Debüt- und Bestsellerautoren, Interviews
      mit Insidern aus der Buchbranche, kulturpolitische Beiträge, etwa zum
      Urheberrecht, aktuelle News, Terminkalender für Literaturwettbewerbe und
      Stipendien, Rezensionen zu Schreibratgebern, Kleinanzeigen.
    </Text>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      »der selfpublisher«
    </Heading>
    <Text variant="default">
      Selfpublisher und ihre Dienstleister – starke Teams für starke Bücher! Mit
      einer Anzeige im <span sx={{ fontWeight: "bold" }}>»selfpublisher«</span>{" "}
      finden Sie zusammen! Die Mediadaten von »der selfpublisher« – Deutschlands
      1. Selfpublishing-Magazin – stehen hier zum Download für Sie bereit.
    </Text>
    <Text variant="default">
      Herausgeber des »selfpublishers« ist seit 2018 der{" "}
      <Styled.a
        href="https://www.selfpublisher-verband.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Selfpublisher-Verband e.V.
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />
    </Text>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      Mediadaten Autorenwelt-Newsletter
    </Heading>
    <Text variant="default">
      Der Uschtrin Verlag erstellt im Auftrag der Autorenwelt GmbH zwei
      Newsletter: den Autorenwelt-Newsletter{" "}
      <span sx={{ fontWeight: "bold" }}>»Preise & Stipendien«</span> und den
      Autorenwelt-Newsletter{" "}
      <span sx={{ fontWeight: "bold" }}>»News aus dem Literaturbetrieb«</span>.
      Für beide Newsletter übernimmt der Uschtrin Verlag das Handling von
      Anzeigen.
    </Text>
    <Text variant="default">
      Kontakt: Sandra Uschtrin
      <br />
      E-Mail:{" "}
      <Styled.a href="mailto:sandra.uschtrin@autorenwelt.de">
        sandra.uschtrin@autorenwelt.de
      </Styled.a>
      <br />
      Telefon: <Styled.a href="tel:+49 89 / 21528793">+49 89 / 21528793</Styled.a>
    </Text>
    <Text variant="default">
      Die Mediadaten stehen hier zum Download bereit:
      <br />
      <Styled.a href={mediadatenAutorenwelt}>
        mediadaten_autorenwelt-newsletter.pdf
      </Styled.a>
    </Text>
    <Text variant="default"></Text>
  </Layout>
)

export default Page
